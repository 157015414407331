// tslint:disable: no-any
import { AxiosResponse } from "axios"
import { Dispatch } from "redux"
import { History } from "history"

import API from "../../Api"
import AuthHelper, { UserAuth } from "../../helpers/AuthHelper"
import { LoginResponse } from "../../types/AuthType"

export interface AdminCredentials {
  userName: string
  password: string
}

export interface ResetPasswordCredentials {
  email: string
  password: string
  passwordConfirm: string
}

export interface NewPassword {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

export interface ForgotPasswordCredentials {
  email: string
}

export interface MFAChallengeRequest {
  contactType: string
  phoneType: string
}

export interface MFAVerifyRequest {
  phoneType: string
  code: string
}

export enum MFAFlowStatus {
  MFAInactive = 0,
  MFAChallenge,
  MFAVerify,
}

export interface AuthActions {
  createPassword: (password: string) => any
  changePassword: (password: string, oldPassword: string) => void
  forgotPassword: (email: string) => any
  updateTosAccepted: (userId: string, tosAccepted: boolean) => any
  login: (user: AdminCredentials, history: History, useMfa: boolean, errorMessage?: string) => any
  logout: (history: History) => any
  resetPassword: (email: string, password: string, token: string) => any
  getUserStatus: (userId: string, history: History) => void
  resetUpdatePasswordError: () => void
  verifyMfaCode: (code: string, phoneType: string, history: History) => any
  challengeMfaCode: (contactType: string, phoneType: string) => any
}

class Auth {
  public static CREATE_ADMIN_PASSWORD_TRIGGER = "CREATE_ADMIN_PASSWORD_TRIGGER"
  public static CREATE_ADMIN_PASSWORD_SUCCESS = "CREATE_ADMIN_PASSWORD_SUCCESS"
  public static CREATE_ADMIN_PASSWORD_ERROR = "CREATE_ADMIN_PASSWORD_ERROR"

  public static RESET_ADMIN_PASSWORD_TRIGGER = "RESET_ADMIN_PASSWORD_TRIGGER"
  public static RESET_ADMIN_PASSWORD_SUCCESS = "RESET_ADMIN_PASSWORD_SUCCESS"
  public static RESET_ADMIN_PASSWORD_ERROR = "RESET_ADMIN_PASSWORD_ERROR"

  public static FORGOT_ADMIN_PASSWORD_TRIGGER = "FORGOT_ADMIN_PASSWORD_TRIGGER"
  public static FORGOT_ADMIN_PASSWORD_SUCCESS = "FORGOT_ADMIN_PASSWORD_SUCCESS"
  public static FORGOT_ADMIN_PASSWORD_ERROR = "FORGOT_ADMIN_PASSWORD_ERROR"

  public static LOGIN_ADMIN_ERROR = "LOGIN_ADMIN_ERROR"
  public static LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS"
  public static LOGIN_ADMIN_TRIGGERED = "LOGIN_ADMIN_TRIGGERED"

  public static LOGOUT_ADMIN = "LOGOUT_ADMIN"

  public static PASSWORD_UPDATED = "PASSWORD_UPDATED"
  public static PASSWORD_UPDATE_ERROR = "PASSWORD_UPDATE_ERROR"

  public static RESET_UPDATE_PASSWORD_ERROR = "RESET_UPDATE_PASSWORD_ERROR"

  public static UPDATE_TOS_TRIGGER = "UPDATE_TOS_TRIGGER"
  public static UPDATE_TOS_SUCCESS = "UPDATE_TOS_SUCCESS"
  public static UPDATE_TOS_ERROR = "UPDATE_TOS_ERROR"

  public static GET_USER_STATUS_TRIGGER = "GET_USER_STATUS_TRIGGER"
  public static GET_USER_STATUS_SUCCESS = "GET_USER_STATUS_SUCCESS"
  public static GET_USER_STATUS_ERROR = "GET_USER_STATUS_ERROR"

  public static MFA_CHALLENGE_TRIGGER = "MFA_CHALLENGE_TRIGGER"
  public static MFA_CHALLENGE_SUCCESS = "MFA_CHALLENGE_SUCCESS"
  public static MFA_CHALLENGE_ERROR = "MFA_CHALLENGE_ERROR"

  public static MFA_VERIFY_TRIGGER = "MFA_VERIFY_TRIGGER"
  public static MFA_VERIFY_SUCCESS = "MFA_VERIFY_SUCCESS"
  public static MFA_VERIFY_ERROR = "MFA_VERIFY_ERROR"

  public static MFA_LOGIN_ENDPOINT = "/v2/auth/mfa-login"
  public static LOGIN_ENDPOINT = "/v2/auth/login"

  public static login =
    (credentials: AdminCredentials, history: History, useMfa = false) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch({
          payload: { credentials },
          type: Auth.LOGIN_ADMIN_TRIGGERED,
        })
        const endpoint = Auth.MFA_LOGIN_ENDPOINT
        const {
          data: { data },
        } = await API.post<LoginResponse>(endpoint, credentials)
        if (!data) {
          dispatch({
            type: Auth.LOGIN_ADMIN_ERROR,
            payload: "Could not proceed with login",
          })
          return
        }
        AuthHelper.setUpPersistentData({ ...data, userName: credentials.userName })

        if (data.mfa && data.mfa.mfaRequired && !data.mfa.mfaVerified) {
          dispatch({
            payload: {
              user: data || {},
            },
            type: Auth.LOGIN_ADMIN_SUCCESS,
          })
          history.push("/public/mfa")
          return
        }

        const { organizationId, hasLoggableProfiles }: UserAuth = AuthHelper.getUserAuth()
        if (hasLoggableProfiles) {
          history.push("/public/organizations")
          return
        }
        const {
          data: {
            data: { organization },
          },
        } = await API.get(`/v1/organizations/${organizationId}`, {
          headers: AuthHelper.getAdminHeaders(),
        })
        AuthHelper.setSelectedOrganization(organization)
        dispatch({
          payload: {
            user: data || {},
          },
          type: Auth.LOGIN_ADMIN_SUCCESS,
        })
      } catch (err: any) {
        console.info("Login error", err)
        dispatch({
          type: Auth.LOGIN_ADMIN_ERROR,
          payload: err.response?.data?.message || "Oops... looks like an error occurred!",
        })
      }
    }

  public static logout = (history: History) => {
    AuthHelper.clearStorage()
    history.push("/public/login")

    return {
      type: Auth.LOGOUT_ADMIN,
    }
  }

  public static createPassword = (password: string) => async (dispatch: Dispatch) => {
    let res: AxiosResponse
    const userAuth: UserAuth = AuthHelper.getUserAuth()

    dispatch({
      type: Auth.CREATE_ADMIN_PASSWORD_TRIGGER,
    })

    try {
      res = await API.put(`/v2/people/${userAuth.userId}`, { password }, { headers: AuthHelper.getAdminHeaders() })
    } catch (err) {
      dispatch({
        type: Auth.CREATE_ADMIN_PASSWORD_ERROR,
      })

      return
    }

    if (res.data.data.person) {
      dispatch({
        type: Auth.CREATE_ADMIN_PASSWORD_SUCCESS,
      })
    }
  }

  public static changePassword = (password: string, oldPassword: string) => async (dispatch: Dispatch) => {
    const userAuth: UserAuth = AuthHelper.getUserAuth()

    try {
      await API.put(`/v2/people/${userAuth.userId}`, { password, oldPassword }, { headers: AuthHelper.getAdminHeaders() })

      dispatch({
        type: Auth.PASSWORD_UPDATED,
      })
    } catch (err) {
      dispatch({
        type: Auth.PASSWORD_UPDATE_ERROR,
        payload: {
          errorId: "user.invalid-password",
        },
      })
    }
  }

  public static resetUpdatePasswordError = () => (dispatch: Dispatch) => {
    dispatch({
      type: Auth.RESET_UPDATE_PASSWORD_ERROR,
    })
  }

  public static forgotPassword = (email: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: Auth.FORGOT_ADMIN_PASSWORD_TRIGGER,
    })

    try {
      await API.post("/v2/auth/reset-token", { email })

      dispatch({
        type: Auth.FORGOT_ADMIN_PASSWORD_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Auth.FORGOT_ADMIN_PASSWORD_ERROR,
      })

      return
    }
  }

  public static resetPassword = (email: string, password: string, token: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: Auth.RESET_ADMIN_PASSWORD_TRIGGER,
    })

    try {
      await API.post("/v2/auth/reset-password", { email, password, token })

      dispatch({
        type: Auth.RESET_ADMIN_PASSWORD_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Auth.RESET_ADMIN_PASSWORD_ERROR,
      })

      return
    }
  }

  public static updateTosAccepted = (userId: string, tosAccepted: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: Auth.UPDATE_TOS_TRIGGER,
    })

    try {
      await API.put(`/v2/people/${userId}/updatetos`, { tosAccepted }, { headers: AuthHelper.getAdminHeaders() })

      dispatch({
        type: Auth.UPDATE_TOS_SUCCESS,
      })
      AuthHelper.setTosAccepted(tosAccepted.toString())
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Auth.UPDATE_TOS_ERROR,
      })

      return
    }
  }

  public static getUserStatus = (userId: string, history: History) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: Auth.GET_USER_STATUS_TRIGGER,
        payload: "Could not get user status",
      })
      const res: AxiosResponse = await API.get(`/v2/people/${userId}/password-status`, { headers: AuthHelper.getAdminHeaders() })
      if (res.data.data) {
        dispatch({
          type: Auth.GET_USER_STATUS_SUCCESS,
          payload: res.data.data,
        })
        return
      }
      dispatch({
        type: Auth.GET_USER_STATUS_ERROR,
        payload: "Could not get user status",
      })
    } catch (err) {
      dispatch({
        type: Auth.GET_USER_STATUS_ERROR,
        payload: "Could not get user status",
      })
      AuthHelper.clearStorage()
      history.push("/public/login")
      window.location.reload()
    }
  }

  public static challengeMfaCode = (contactType: string, phoneType: string) => async (dispatch: Dispatch) => {
    const payload = { contactType, phoneType }

    try {
      dispatch({
        type: Auth.MFA_CHALLENGE_TRIGGER,
        payload,
      })

      const res: AxiosResponse = await API.post(`/v2/auth/mfa-login/challenge`, { ...payload }, { headers: AuthHelper.getAdminHeaders() })

      if (!res.data.data) {
        dispatch({
          type: Auth.MFA_CHALLENGE_ERROR,
          payload: res.data.data,
        })
        return
      }

      dispatch({
        type: Auth.MFA_CHALLENGE_SUCCESS,
        payload: res.data.data,
      })
    } catch (err) {
      dispatch({
        type: Auth.MFA_CHALLENGE_ERROR,
        payload,
      })
    }
  }

  public static verifyMfaCode = (code: string, phoneType: string, history: History) => async (dispatch: Dispatch) => {
    const payload = {
      code,
      phoneType,
    }
    try {
      dispatch({
        type: Auth.MFA_VERIFY_TRIGGER,
        payload,
      })

      const res: AxiosResponse = await API.post(`/v2/auth/mfa-login/verify`, { ...payload }, { headers: AuthHelper.getAdminHeaders() })

      if (!res.data.data || (res.data.data && !res.data.data.mfa.mfaVerified)) {
        dispatch({
          type: Auth.MFA_VERIFY_ERROR,
          payload: res.data.data,
        })
        return
      }

      AuthHelper.refreshToken(res.data.data.refreshToken)
      AuthHelper.setMFAcompleted()

      const { organizationId, hasLoggableProfiles }: UserAuth = AuthHelper.getUserAuth()
      if (hasLoggableProfiles) {
        history.push("/public/organizations")
        return
      }
      const {
        data: {
          data: { organization },
        },
      } = await API.get(`/v1/organizations/${organizationId}`, {
        headers: AuthHelper.getAdminHeaders(),
      })
      AuthHelper.setSelectedOrganization(organization)

      dispatch({
        type: Auth.MFA_VERIFY_SUCCESS,
        payload: res.data.data,
      })

      history.push(`/app/organizations/${organizationId}`)
    } catch (err) {
      dispatch({
        type: Auth.MFA_VERIFY_ERROR,
        payload,
      })
    }
  }
}

export default Auth
